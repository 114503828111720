<template>

  <!--====== APPIE PROJECT PART START ======-->

  <section class="appie-project-area pb-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="appie-project-box wow animated slideInUp" data-wow-duration="1000ms" data-wow-delay="0ms">
            <div class="container">
              <div class="section-header text-center">
                <h2>
                  LIÊN HỆ VỚI CHÚNG TÔI
                </h2>
                <p class="pt-2 pb-2">Luôn lắng nghe tiếp thu ý kiến của khách hàng, sẵn sàng thay đổi để tốt hơn.</p>
              </div>

              <div class="row contact-info lien-he" id="contact">
                <div class="col-md-4">
                  <div class="contact-address text-center">
                    <i class="fas fa-map-marker icon"></i>
                    <h3 class="title">Địa chỉ</h3>
                    <address class="content">
                        <span>{{address}}<br/></span>
                        <span class="address2">{{address2}}</span>
                    </address>
                  </div>
                </div>

                <div class="col-md-4 text-center">
                  <div class="contact-phone">
                    <i class="fas fa-phone icon"></i>
                    <h3 class="title">Số điện thoại</h3>
                    <p>
                      <a :href="phoneCall" class="content">{{ phoneShow }}</a>
                    </p>
                  </div>
                </div>

                <div class="col-md-4 text-center">
                  <div class="contact-email">
                    <i class="fas fa-envelope icon"></i>
                    <h3 class="title">Email</h3>
                    <p>
                      <a :href="emailSend" class="content">{{ email}}</a>
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--====== APPIE PROJECT PART ENDS ======-->
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    address: {
      type: String,
    },
    address2: {
      type: String,
    },
    phoneCall: {
      type: String,
    },
    phoneShow: {
      type: String,
    },
    email: {
      type: String,
    },
    emailSend: {
      type: String,
    },
  }

}
</script>

<style>

</style>
