<template>
  <!--====== APPIE FAQ PART START ======-->

  <section class="appie-faq-area pb-95 pt-50">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="appie-section-title text-center">
            <h3 class="appie-title">{{ title }}</h3>
            <p>{{ description }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="faq-accordion wow fadeInRight mt-30" data-wow-duration="1500ms">
            <div class="accrodion-grp animated fadeIn faq-accrodion wow" data-wow-duration="1500ms"
                 data-grp-name="faq-accrodion">
              <div class="accrodion" @click.prevent="OpenQuestion(1)" :class="[showQuestion === 1?'active':'']">
                <div class="accrodion-inner">
                  <div class="accrodion-title">
                    <h4>Liên hệ với nhà xe bằng cách nào ?</h4>
                  </div>
                  <div class="accrodion-content" :style="[showQuestion === 1?{'display':'block'}:{'display':'none'}]">
                    <div class="inner">
                      <p>
                        Bạn có thể gọi ngay cho chúng tôi qua số điện thoại: <span class="phoneNumber nHover"
                                                                                   @click="callPhoneNumber()">{{
                          phoneShow
                        }}</span>
                        <br/>
                        Hoặc qua zalo: <span class="phoneNumber nHover" @click="callPhoneZalo()">{{ phoneShow }}</span>
                      </p>
                    </div><!-- /.inner -->
                  </div>
                </div><!-- /.accrodion-inner -->
              </div>
              <div class="accrodion  " :class="[showQuestion === 2?'active':'']" @click.prevent="OpenQuestion(2)">
                <div class="accrodion-inner">
                  <div class="accrodion-title">
                    <h4>Thời gian phục vụ của nhà xe ?</h4>
                  </div>
                  <div class="accrodion-content" :style="[showQuestion === 2?{'display':'block'}:{'display':'none'}]">
                    <div class="inner">
                      <p>Chúng tôi phục vụ 24h mỗi ngày và 7 ngày trong tuần</p>
                    </div><!-- /.inner -->
                  </div>
                </div><!-- /.accrodion-inner -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="faq-accordion wow fadeInRight mt-30" data-wow-duration="1500ms">
            <div class="accrodion-grp animated fadeIn faq-accrodion wow" data-wow-duration="1500ms"
                 data-grp-name="faq-accrodion">
              <div class="accrodion " :class="[showQuestion === 3?'active':'']" @click.prevent="OpenQuestion(3)">
                <div class="accrodion-inner">
                  <div class="accrodion-title">
                    <h4>Loại xe phục vụ ?</h4>
                  </div>
                  <div class="accrodion-content" :style="[showQuestion === 3?{'display':'block'}:{'display':'none'}]">
                    <div class="inner">
                      <p>Chúng tôi cung cấp các loại xe 4 chỗ, 7 chỗ đời mới. Đảm bảo quý khách hàng sẽ hài lòng khi sử dụng dịch vụ bên chúng tôi.</p>
                    </div><!-- /.inner -->
                  </div>
                </div><!-- /.accrodion-inner -->
              </div>
              <div class="accrodion" :class="[showQuestion ===4?'active':'']" @click.prevent="OpenQuestion(4)">
                <div class="accrodion-inner">
                  <div class="accrodion-title">
                    <h4>Địa điểm nhận xe nếu tôi muôn thuê xe tự lái ?</h4>
                  </div>
                  <div class="accrodion-content" :style="[showQuestion === 4?{'display':'block'}:{'display':'none'}]">
                    <div class="inner">
                      <p>Chúng tôi sẽ liên hệ trực tiếp với khách hàng để thống nhất địa điểm nhận xe.</p>
                    </div><!-- /.inner -->
                  </div>
                </div><!-- /.accrodion-inner -->
              </div>
            </div>
          </div>
        </div>
<!--        <div class="col-lg-12">-->
<!--          <div class="faq-text text-center pt-40">-->
<!--            <p>Can't find an answer? <a href="mailto:demo@gmail.com">Email us</a></p>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </section>

  <!--====== APPIE FAQ PART ENDS ======-->
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    phoneShow: {
      type: String,
    },
    phoneCall: {
      type: String,
    },
    phoneZalo: {
      type: String,
    },
  },
  data() {
    return {
      showQuestion: 1,
    }
  },
  methods: {
    OpenQuestion(value) {
      this.showQuestion = value
    },
    callPhoneNumber() {
      window.location.href = this.phoneCall
    },
    callPhoneZalo() {
      window.open(this.phoneZalo, '_blank')
    }
  }

}
</script>

<style>

</style>
