<template>
  <!--====== APPIE FEATURES PART START ======-->
  <section class="appie-features-area pt-75 pb-75" id="features">
    <div class="container">
      <div class="row pb-20">
        <div class="col-lg-12">
          <div class="appie-section-title text-center">
            <h3 class="appie-title">DỊCH VỤ CỦA CHÚNG TÔI</h3>
            <p>Chúng tôi cung cấp các dịch vụ phục vụ nhu cầu thiết yếu của khách hàng: đưa đón khắp các tỉnh thành khu
              vực miền trung, tây nguyên ..., đưa đón sân bay, bệnh viện, cho thuê xe giá rẻ...</p>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-lg-3">
          <div class="appie-features-tabs-btn">
            <div class="nav flex-column nav-pills" role="tablist" aria-orientation="vertical">
              <a @click.prevent="selectFeature('setting')" class="nav-link nHover"
                 :class="[selectedTab==='setting'?'active':'']" data-toggle="pill" role="tab"
                 aria-controls="v-pills-home" aria-selected="true"><i class="fas fa-cog"></i> Đưa đón sân bay</a>
              <a @click.prevent="selectFeature('report')" class="nav-link nHover"
                 :class="[selectedTab==='report'?'active':'']"
                 data-toggle="pill" role="tab" aria-controls="v-pills-profile" aria-selected="false"><i
                  class="fas fa-exclamation-triangle"></i> Du lịch tham quan</a>
              <a @click.prevent="selectFeature('notice')" class="nav-link nHover"
                 :class="[selectedTab==='notice'?'active':'']"
                 data-toggle="pill" role="tab" aria-controls="v-pills-messages" aria-selected="false"><i
                  class="fas fa-bell"></i> Xe cưới - xe đi tỉnh</a>
              <a @click.prevent="selectFeature('lock')" class="nav-link nHover"
                 :class="[selectedTab==='lock'?'active':'']"
                 data-toggle="pill" role="tab" aria-controls="v-pills-settings" aria-selected="false"><i
                  class="fas fa-lock"></i> Du lịch - công tác</a>
            </div>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="tab-content">
            <div :class="[selectedTab==='setting'?'active show':'']" class="tab-pane fade " role="tabpanel"
                 aria-labelledby="v-pills-home-tab">
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <div class="appie-features-thumb text-center wow animated fadeInUp" data-wow-duration="2000ms"
                       data-wow-delay="200ms">
                    <img src="https://nissan.itphonui.com/Uploads/files/4/fol-ouprnvelao/637947317112924789.jpg"
                         alt=""/>
                    <!--                    <img src="@/assets/images/features-thumb-1.png" alt=""/>-->
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="appie-features-content wow animated fadeInRight" data-wow-duration="2000ms"
                       data-wow-delay="600ms">
                    <span>Dịch vụ</span>
                    <h3 class="title">ĐƯA ĐÓN SÂN BAY</h3>
                    <p>Ngay khi xuống máy bay, chúng tôi đã chờ sẵn bạn ở cổng đến...</p>
                  </div>
                </div>
              </div>
            </div>
            <div :class="[selectedTab==='report'?'active show':'']" class="tab-pane fade" role="tabpanel"
                 aria-labelledby="v-pills-profile-tab">
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <div class="appie-features-thumb text-center animated fadeInUp" data-wow-duration="2000ms"
                       data-wow-delay="200ms">
                    <img src="https://nissan.itphonui.com/Uploads/files/4/fol-kvldirhasp/637947317224134233.jpg"
                         alt=""/>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="appie-features-content animated fadeInRight" data-wow-duration="2000ms"
                       data-wow-delay="600ms">
                    <span>Dịch vụ</span>
                    <h3 class="title">DU LỊCH - THAM QUAN</h3>
                    <p>Với kinh nghiệm nhiều năm, chúng tôi tự tin phục vụ bạn tốt nhất trong những chuyến du lịch - tham quan với cơ quan, gia đình ....</p>
                    <!--                    <a class="main-btn" href="#">Learn More</a>-->
                  </div>
                </div>
              </div>
            </div>
            <div :class="[selectedTab==='notice'?'active show':'']" class="tab-pane fade" role="tabpanel"
                 aria-labelledby="v-pills-messages-tab">
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <div class="appie-features-thumb text-center animated fadeInUp" data-wow-duration="2000ms"
                       data-wow-delay="200ms">
                    <img src="https://nissan.itphonui.com/Uploads/files/4/fol-lvgtiknepk/637947317760094436.jpg"
                         alt=""/>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="appie-features-content animated fadeInRight" data-wow-duration="2000ms"
                       data-wow-delay="600ms">
                    <span>Dịch vụ</span>
                    <h3 class="title">XE CƯỚI - XE ĐI TỈNH</h3>
                    <p>Một ngày trọng đại của đời người - Chúng tôi hân hạnh được phục vụ quý khách.</p>
                    <!--                    <a class="main-btn" href="#">Learn More</a>-->
                  </div>
                </div>
              </div>
            </div>
            <div :class="[selectedTab==='lock'?'active show':'']" class="tab-pane fade" role="tabpanel"
                 aria-labelledby="v-pills-settings-tab">
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <div class="appie-features-thumb text-center animated fadeInUp" data-wow-duration="2000ms"
                       data-wow-delay="200ms">
                    <img src="https://nissan.itphonui.com/Uploads/files/4/fol-laqaaobogo/637947317645670160.jpg"
                         alt=""/>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="appie-features-content animated fadeInRight" data-wow-duration="2000ms"
                       data-wow-delay="600ms">
                    <span>Dịch vụ</span>
                    <h3 class="title">DU LỊCH - CÔNG TÁC</h3>
                    <p>Với những chuyến công tác xa - bạn cứ yên tâm với đội ngũ tài xế nhiều năm kinh nghiệm của chúng tôi.</p>
                    <!--                    <a class="main-btn" href="#">Learn More</a>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row clearfix pt-80">
        <div class="col-xs-12 col-sm-6 col-md-4 pb-5">
          <img src="/images/huyen/peliku.jpg" class="huyenImage" alt="Taxi Gia Lai 247"/>
          <span class="huyenName">Pleiku</span>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 pb-5">
          <img src="/images/huyen/ankhe.jpg" class="huyenImage" alt="Taxi Gia Lai 247"/>
          <span class="huyenName">An khê</span>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 pb-5">
          <img src="/images/huyen/dakdoa.jpg" class="huyenImage" alt="Taxi Gia Lai 247"/>
          <span class="huyenName">Đak Đoa</span>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 pb-5">
          <img src="/images/huyen/mangyang.jpg" class="huyenImage" alt="Taxi Gia Lai 247"/>
          <span class="huyenName">Mang Yang</span>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 pb-5">
          <img src="/images/huyen/iapa.jpg" class="huyenImage" alt="Taxi Gia Lai 247"/>
          <span class="huyenName">IaPa</span>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 pb-5">
          <img src="/images/huyen/chuse.jpg" class="huyenImage" alt="Taxi Gia Lai 247"/>
          <span class="huyenName">Chư Sê</span>
        </div>
      </div>
    </div>
    <div class="features-shape-1">
      <img src="@/assets/images/shape/shape-6.png" alt="">
    </div>
    <div class="features-shape-2">
      <img src="@/assets/images/shape/shape-7.png" alt="">
    </div>
    <div class="features-shape-3">
      <img src="@/assets/images/shape/shape-8.png" alt="">
    </div>
  </section>

  <!--====== APPIE FEATURES PART ENDS ======-->
</template>

<script>
export default {
  props: {
    phoneCall: {
      type: String,
    },
    phoneShow: {
      type: String,
    },
  },
  data() {
    return {
      selectedTab: 'setting',
      switchPlan: true,
    }
  },
  methods: {
    selectFeature(name) {
      this.selectedTab = name
    },
  }
}
</script>

<style>


</style>
