<template>
  <!--====== APPIE FEATURES 2 PART START ======-->

  <section class="appie-features-area-2 pt-90 pb-100" id="features">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="appie-section-title appie-section-title-2 text-center">
            <h3 class="appie-title">{{ title }}<br> {{ more_title }}</h3>
            <p>{{ description }}</p>
          </div>
        </div>
      </div>
      <div class="row mt-30 align-items-center">
        <div class="col-lg-6">
          <div class="appie-features-boxes">
            <div class="appie-features-box-item">
              <h4 class="title nHover" @click="callTelephone()">{{ phoneShow }} </h4>
              <p>Hotline</p>
            </div>
            <div class="appie-features-box-item item-2">
              <h4 class="title">Uy tín - Chất lượng</h4>
              <!--              <p>The bee's knees chancer car boot absolutely.</p>-->
            </div>
            <div class="appie-features-box-item item-3">
              <h4 class="title">Phục vụ 24/7</h4>
              <!--              <p>The bee's knees chancer car boot absolutely.</p>-->
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="appie-features-thumb wow animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="200ms">
            <div class="row clearfix">
              <div class="col-12 contactTitle">*. Họ và tên</div>
              <div class="col-12">
                <input type="text" class="form-control" placeholder="Nhập họ và tên ..." v-model="form.name">
              </div>
            </div>
            <div class="row clearfix pt-2">
              <div class="col-12 contactTitle">*. Số điện thoại</div>
              <div class="col-12">
                <input type="text" class="form-control" placeholder="Nhập số điện thoại ..." v-model="form.phone">
              </div>
            </div>
            <div class="row clearfix pt-2">
              <div class="col-12 contactTitle">*. Điểm đón</div>
              <div class="col-12">
                <input type="text" class="form-control" placeholder="Nhập điểm đón ..." v-model="form.diemDon">
              </div>
            </div>
            <div class="row clearfix pt-2">
              <div class="col-12 contactTitle">*. Điểm đến</div>
              <div class="col-12">
                <input type="text" class="form-control" placeholder="Nhập điểm đến ..." v-model="form.diemDen">
              </div>
            </div>
            <div class="row clearfix pt-2">
              <div class="col-12">
                <div class="row clearfix">
                  <div class="col-6">
                    <div class="row clearfix">
                      <div class="col-12 contactTitle">*. Thời gian</div>
                      <div class="col-12">
                        <input type="date"
                               class="form-control"
                               placeholder="Nhập thời gian đón ..."
                               v-model="form.thoiGian">
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row clearfix">
                      <div class="col-12 contactTitle">*. Loại xe</div>
                      <div class="col-12">
                        <select class="form-control" v-model="form.loaiXe">
                          <option value="1">Xe 4 chỗ</option>
                          <option value="2">Xe 5 chỗ</option>
                          <option value="3">Xe 7 chỗ</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row clearfix pt-3">
              <div class="col-12">
                <button class="btn-danger form-control" type="button" @click="registerCar()">ĐẶT XE NGAY</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="features-shape-1">
      <img src="@/assets/images/shape/shape-15.png" alt="">
    </div>
    <div class="features-shape-2">
      <img src="@/assets/images/shape/shape-14.png" alt="">
    </div>
    <div class="features-shape-3">
      <img src="@/assets/images/shape/shape-13.png" alt="">
    </div>
  </section>

  <!--====== APPIE FEATURES 2 PART ENDS ======-->
</template>

<script>
export default {
  name: 'HomeContact',
  props: {
    title: {
      type: String,
    },
    phoneShow: {
      type: String,
    },
    phoneCall: {
      type: String,
    },
  },
  data() {
    return {
      form: {
        name: '',
        phone: '',
        diemDon: '',
        diemDen: '',
        thoiGian: '',
        loaiXe: '1'
      }
    }
  },
  mounted() {
    //this.form.thoiGian = this.returnTimeNow()
  },
  methods: {
    callTelephone() {
      window.location.href = this.phoneCall
    },
    registerCar() {
      if (this.form.name === '') {
        return this.$swal.fire({
          title: 'Thông báo',
          text: 'Vui lòng nhập họ tên',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Không đồng ý'
        })
      }
      if (this.form.phone === '') {
        return this.$swal.fire({
          title: 'Thông báo',
          text: 'Vui lòng nhập số điện thoại',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Không đồng ý'
        })
      }
      if (this.form.diemDon === '') {
        return this.$swal.fire({
          title: 'Thông báo',
          text: 'Vui lòng nhập điểm đón',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Không đồng ý'
        })
      }
      if (this.form.diemDen === '') {
        return this.$swal.fire({
          title: 'Thông báo',
          text: 'Vui lòng nhập điểm đến',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Không đồng ý'
        })
      }
      if (this.form.thoiGian === '') {
        return this.$swal.fire({
          title: 'Thông báo',
          text: 'Vui lòng thời gian đón',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Không đồng ý'
        })
      }


    },
    returnTimeNow() {
      //const today = new Date();
      // const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      // const time = today.getHours() + ":" + today.getMinutes() + ":" +
      //     today.getSeconds();
      // const dateTime = date + ' ' + time;
      // today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
      //return (today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear()
    }
  }
}
</script>

<style>

</style>
