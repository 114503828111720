<template>
  <div>
    <div v-if="showloading">
      <loader-appie/>
    </div>
    <div v-else>
      <!-- <div class="loader-wrap">
          <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
          <div class="layer layer-one"><span class="overlay"></span></div>
          <div class="layer layer-two"><span class="overlay"></span></div>
          <div class="layer layer-three"><span class="overlay"></span></div>
      </div> -->

      <hero-home-one welcome_message="Chào mừng bạn đến với"
                     slogan="TAXI GIA LAI 24/7"
                     description="Chúng tôi luôn mang đến quý khách hàng chất lượng phục vụ tốt nhất"
                     first_button_content="Download for iOS"
                     first_button_icon="fab fa-apple"
                     :second_button_content="phoneShow"
                     second_button_icon="fab fa-phone"
                     :second_button_phone="returnCallNow('phoneCall')"/>

      <services-home-one :grid_items="itemsGrid"
                         section_heading="Vì sao chọn chúng tôi"
                         description="Với đội ngũ tài xế nhiều kinh nghiệm sau nhiều năm hoạt động, chúng tôi luôn mong muốn làm hài lòng khách hàng."/>

      <features-home-one :phone-show="phoneShow"/>

      <traffic-home-one small_title="Dịch vụ chất lượng cao"
                        big_title="DỊCH VỤ TAXI, DU LỊCH"
                        big_title2="CHO THUÊ XE CÓ TÀI VÀ TỰ LÁI"
                        description="Hiểu được những nhu cầu cần thiết của khách hàng, dịch vụ cho thuê xe du lịch chuyên nghiệp Taxi Gia Lai 24/7 đã cung cấp dịch vụ cho thuê xe tự lái, với các dòng xe 4 chỗ, 7 chỗ, từ cao cấp cho đến bình dân, giá cả phù hợp, thủ tục nhanh gọn giao xe tận nơi.."
                        :call-now="returnCallNow('phoneCall')"
                        :features="features"/>

      <!--      <testimonial-home-one :sliderContents="sliderContents"/>-->

      <faq-home-one title="CÂU HỎI THƯỜNG GẶP"
                    description=""
                    :phone-call="returnCallNow('phoneCall')"
                    :phone-show="phoneShow"
                    :phone-zalo="returnCallZalo()"/>

      <home-contact :title="'ĐẶT XE NGAY'"
                    :phone-show="phoneShow"
                    :phone-call="returnCallNow()"/>

      <project-home-one title="Liên hệ chúng tôi"
                        description=""
                        :phone-show="phoneShow"
                        :phone-call="returnCallNow()"
                        :address="address1"
                        :address2="address2"
                        :email="email"
                        :email-send="returnCallEmail()"/>
      <hotline :phone-show="phoneShow"
               :phone-call="returnCallNow()"
               :phone-zalo="returnCallZalo()"/>
      <div class="back-to-top">
        <a href="#"><i class="fal fa-arrow-up"></i></a>
      </div>

    </div>
  </div>
</template>
<script>
import testmonialAvater from "@/assets/images/testimonial-user.png"
import traffic_thumb from '@/assets/images/traffic-thumb.png'
import HeroHomeOne from './HeroHomeOne.vue'
import ServicesHomeOne from './ServicesHomeOne.vue'
import FeaturesHomeOne from './FeaturesHomeOne.vue'
import TrafficHomeOne from './TrafficHomeOne.vue'
import FaqHomeOne from './FaqHomeOne.vue'
import ProjectHomeOne from './ProjectHomeOne.vue'
import LoaderAppie from '../LoaderAppie.vue'
import Hotline from "@/components/HomeOne/Hotline.vue";
import HomeContact from "./HomeContact.vue";

export default {
  name: 'HomeOne',
  components: {
    HomeContact,
    Hotline,
    HeroHomeOne,
    ServicesHomeOne,
    FeaturesHomeOne,
    TrafficHomeOne,
    FaqHomeOne,
    ProjectHomeOne,
    LoaderAppie,
  },
  data() {
    return {
      phoneShow: '0838 81 6879',
      phoneCall: '0838816879',
      address1: '99 Ngô Quyền - Xã Biển Hồ',
      address2: 'Tp.Pleiku - Gia Lai',
      email: 'hatuongthu6879@gmail.com',
      traffic_thumb: traffic_thumb,
      testmonialAvater: testmonialAvater,
      sidebar: false,
      showloading: true,
      itemsGrid: [
        {
          count: 1,
          icon: 'fas fa-car service-icon',
          title: 'Phục vụ 24/7',
          content: 'Đầy đủ các loại xe: 4 chỗ, 7 chỗ, 16 chỗ. Có cho thuê xe đi bất cứ đâu theo nhu cầu của khách hàng.',
          customClass: ''

        },
        {
          count: 2,
          icon: 'fas fa-users service-icon',
          title: 'Đội ngũ tài xế chuyên nghiệp',
          content: 'Với nhiều năm kinh nghiệm trong lĩnh vực taxi, chúng tôi luôn sẵn sàng phục vụ.',
          customClass: 'item-2'

        },
        {
          count: 3,
          icon: 'fas fa-map-marker-alt service-icon',
          title: 'Đưa đón tận nơi',
          content: 'Phục vụ đưa đón khắp các tỉnh thành khu vực miền trung, tây nguyên ... Việt Nam.',
          customClass: 'item-3',

        },
        {
          count: 4,
          icon: 'fas fa-money-bill-wave service-icon',
          title: 'Chi phí hợp lý',
          content: 'Chi phí di chuyển rẻ, đáp ứng mọi nhu cầu của khách hàng.',
          customClass: 'item-4'

        },
      ],
      features: [
        {
          title: 'Tư vấn',
          bg: '',// create class for style
          description: ''

        },
        {
          title: 'Báo giá miễn phí',
          bg: 'item-2',// create class for style
          description: ''

        },
        {
          title: 'Xe đời mới',
          bg: 'item-3',// create class for style
          description: ''

        },
        {
          title: 'Giao xe tận nơi',
          bg: 'item-4',// create class for style
          description: ''

        },
      ],
      sliderContents: [
        {
          title: 'Jason Response',
          date: 'April 14, 2022',
          description: 'Why I say old chap that is spiffing lurgy Oxford cracking goal bleeder, bog-standard Harry brilliant mush a blinding shot starkers.',
          ratings: 5,
          avatar: testmonialAvater,
        },
        {
          title: 'Jason Response',
          date: 'April 14, 2022',
          description: 'Why I say old chap that is spiffing lurgy Oxford cracking goal bleeder, bog-standard Harry brilliant mush a blinding shot starkers.',
          ratings: 5,
          avatar: testmonialAvater,
        },
        {
          title: 'Jason Response',
          date: 'April 14, 2022',
          description: 'Why I say old chap that is spiffing lurgy Oxford cracking goal bleeder, bog-standard Harry brilliant mush a blinding shot starkers.',
          ratings: 2,
          avatar: testmonialAvater,
        },
        {
          title: 'Jason Response',
          date: 'April 14, 2022',
          description: 'Why I say old chap that is spiffing lurgy Oxford cracking goal bleeder, bog-standard Harry brilliant mush a blinding shot starkers.',
          ratings: 3,
          avatar: testmonialAvater,
        }
      ],
    }
  },
  created() {
    this.preLoading();
    document.addEventListener('scroll', this.topToBottom)
  },

  methods: {
    preLoading() {
      setTimeout(() => {
        this.showloading = false
      }, 2000)
    },
    topToBottom() {
      const result = document.querySelector('.back-to-top')
      if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar
    },
    returnCallNow() {
      return 'tel:' + this.phoneCall
    },
    returnCallZalo() {
      return 'https://zalo.me/' + this.phoneCall
    },
    returnCallEmail() {
      return 'mailto:' + this.email
    }
  }

}
</script>

<style>

</style>
