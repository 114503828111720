<template>
  <div class="hotline-phone-ring-wrap">
    <div class="hotline-phone-ring">
      <div class="hotline-phone-ring-circle"></div>
      <div class="hotline-phone-ring-circle-fill"></div>
      <div class="hotline-phone-ring-img-circle">

        <a href="tel: 0905314601" class="pps-btn-img">
          <img src="/images/taxi247/icon-call-nh.png" alt="Liên hệ ngay với chúng tôi" width="50">
        </a>
      </div>
      <a class="zalo" :href="phoneZalo" target="_blank">
        <img src="/images/taxi247/zalo.png" width="50px" alt="zalo">
      </a>
      <a :href="phoneCall" :title="phoneShow" class="a-call">
        {{ phoneShow }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hotline",
  props: {
    phoneCall: {
      type: String,
    },
    phoneShow: {
      type: String,
    },
    phoneZalo: {
      type: String,
    },
  },
}
</script>

<style scoped>

</style>
